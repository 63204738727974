/*.App {*/
/*  text-align: center;*/
/*}*/

@font-face {
  font-family: NeueHaas;
  src: url(./assets/fonts/337111_0_0.woff2) format("woff2");
  font-weight: 500;
  font-display:swap
}
@font-face {
  font-family: NeueHaas;
  src: url(./assets/fonts/NeueHaasDisplay-Bold.ttf) format("ttf");
  font-weight: 550;
  font-display:swap
}
@font-face {
  font-family: NeueHaas;
  src: url(./assets/fonts/337111_1_0.woff2) format("woff2") ;
  font-display:swap
}

@font-face {
  font-family: NeueHaas;
  src: url(./assets/fonts/33F0E7_0_0.woff2) format("woff2");
  font-style: italic;
  font-display:swap
}

@media (min-width: 544px) {
  html{
    font-size: 17px;
  }
  /*h1{*/
  /*font-size: 35px !important;*/
  /*line-height: 40px !important;*/
  /*}*/
  /*h2{*/
  /*  font-size: 27px;*/
  /*  line-height: 31px;*/
  /*}*/
  /*h3{*/
  /*  font-size: 19px;*/
  /*  line-height: 24px;*/
  /*}*/
  /*h4{*/
  /*  font-size: 19px;*/
  /*  line-height: 24px;*/
  /*}*/
  /*p{*/
  /*  font-size: 17px;*/
  /*  line-height: 25px;*/
  /*}*/
}

@media (min-width: 768px) {
  html{
    font-size: 19px;
  }
  .iframe-container{
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .hero-image img{
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .hero-image iframe{
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .hero-quote{
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }
  /*img{*/
  /*  margin-bottom: 2rem !important;*/
  /*  margin-top: 2rem;*/
  /*}*/

  p + img{
    margin-top: 1.5rem !important;
  }
  ol + img{
    margin-top: 1.5rem !important;
  }
  img + p{
    margin-top: 1rem !important;
  }
  iframe + iframe{
    margin-top: 1.5rem;
  }
  /*img + .row{*/
  /*  margin-top: 2.5rem;*/
  /*}*/
  img + h3{
    margin-top: 1.5rem;
  }
  p + .clGridContainer{
    margin-top: 2.5rem;
  }
  .clGridContainer + p{
    margin-top: 1rem;
  }
  p + .image-container{
    margin-top: 1.5rem;
  }
  .image-container + a{
    margin-top: 2.5rem;
  }
  h5 + .clGridContainer{
    margin-top: 2rem;
  }
  .clGridContainer + h4{
    margin-top: 1rem;
  }
  .clGridContainer + h5{
    margin-top: 1rem;
  }
  .clGridContainer + img{
    margin-top: 1rem;
  }
  .overviewContainer + img{
    margin-top: 1.5rem;
  }
  .overviewContainer + h4{
    margin-top: 2rem;
  }
  h3 + p{
    margin-top:1rem;
  }
  h4+p{
    margin-top: 1rem;
  }
  p + h4{
    margin-top: 2rem;
  }
  p + h5{
    margin-top: 2rem;
  }
  .externalLink + p{
    margin-top: 1.5rem;
  }
  h5 + p{
    margin-top: 1.5rem;
  }
  h5 + ol{
    margin-top: 1.5rem;
  }
  /*img+img{*/
  /*  margin-top:1rem;*/
  /*}*/
  .image-container + h4{
    margin-top: 1.5rem;
  }
  .h4-below-caraousel{
    margin-top: 2.5rem!important;
  }
  .image-container + h5{
    margin-top: 1.5rem;
  }
  .image-container + h3{
    margin-top: 1.5rem;
  }
  .image-container + img{
    margin-top: 2.5rem;
  }
  .image-container + p{
    margin-top: 2.5rem !important;
  }
  img + h4{
    margin-top: 1.5rem;
  }
  h4 + img{
    margin-top: 2rem;
  }
  h4+h5{
    margin-top: 1.5rem;
  }
  p+ .margin-top-2{
    margin-top: 2rem;
  }
  .margin-top-2 + h5{
    margin-top: 2rem;
  }
  .my-md-4-5{
  margin-bottom: 2rem !important;
  margin-top: 2rem!important;
}
  .externalLink{
    margin-bottom: 0.5rem;
  }

  .note{
    margin-bottom: 2rem;
  }
  .hiatus-9-container{
    margin-bottom: 2.5rem;
  }
  /*h1{*/
  /*  font-size: 55px ;*/
  /*  line-height: 60px;*/
  /*}*/
  /*h2{*/
  /*  font-size: 42px;*/
  /*  line-height: 46px;*/
  /*}*/
  /*h3{*/
  /*  font-size: 35px;*/
  /*  line-height: 40px;*/
  /*}*/
  /*h4{*/
  /*  font-size: 27px;*/
  /*  line-height: 32px;*/
  /*}*/
  /*p{*/
  /*  font-size: 19px;*/
  /*  line-height: 27px;*/
  /*}*/
}

.white-nav-background{
  background-color: white;
}
.note{
  color: #e61428;
}
.externalLink{
  color: #e61428;
  text-decoration: none;
}
.externalLink:hover{
  text-decoration: underline;
  cursor: pointer;
  color: #e61428;
}
.hiatus-9-container{
  padding-top: 1.5rem;
  background-color: rgb(228,217,213);
}
/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/


.brand{
  text-decoration: inherit;
  color:rgb(26,26,26);
  font-size: 34px;
  font-weight: 600;
}

.navbar-nav > a{
  text-decoration: inherit;
  color:rgb(26,26,26);
  font-size: 1.16rem;
  font-weight: 450;
}

.grid-img{
  max-width:100%;
  max-height:100%;
  width:auto;
  height:auto;
}
.grid-img-title{
  margin-bottom: 1.5rem;
}
.clickable{
  text-decoration: none;
}
.clickable:hover .ar-3x2-figure, .clickable:hover .ar-16x9-figure{
  opacity: 0.6;
  transition: 0.4s ease;
}
.hero-container{
  /*min-height: 70vh;*/
  max-height: 70vh;
  overflow: hidden;
  margin-bottom: 3rem;
}
.manifesto{
  position: relative;
  max-height: 100%;
}
.orbt_background{
  position: relative;
  transform: translateY(-25%);
}
.hiatus_background{
  width: 100%;
}
.nia-background{
  position: relative;
  transform: translateY(-20%);
}
.lockdownPlaybook-Background{
  position: relative;
  transform: translateY(-16%);
}
.timescape-background{
  position: relative;
  transform: translateY(-21%);
}
.thesis-background{
  position: relative;
  transform: translateY(-25%);
}
.bserp-background{
  width: 100%;
  position: relative;
  transform: translateY(-25%);
}
p{
  font-family: NeueHaas,sans-serif;
  //font-size: 19px;
  vertical-align: baseline;
  //line-height: 27px;
  font-weight: normal;
  color: rgb(26,26,26);
  font-feature-settings: 'kern';
  text-rendering: optimizeLegibility;
  font-variant-ligatures: common-ligatures;
}
h1,h2,h3,h4,h5,h6{
  font-family:NeueHaas,"Helvetica Neue", Helvetica,sans-serif;
}

.square{
   position: relative;
  margin-bottom: 1.5rem;
}
.square::after{
  content: "";
  display: block;
  padding-bottom: 100%;
}
.square > img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-fluid{
  margin-bottom: 1.5rem;
}
.thesis h1,.thesis h2,.thesis h3,.thesis h4{
  margin-bottom: 2.5rem;
}
.thesis p{
  margin-bottom: 2rem;
 }
.thesis iframe, .thesis #clGallery{
  margin-bottom: 2.5rem;
}
.thesis img{
  margin-bottom: 1.5rem;
}
/*.imageGallery{*/
/*  margin-bottom: 2.5rem;*/
/*}*/
.ar-18{
  position: relative;
}
.ar-18::after{
  content: "";
  display: block;
  padding-bottom: 19.50%;
}
.ar-18 > p{
  position: absolute;
}
.ar-71{
  position: relative;
  margin-bottom: 0.5rem;
}
.ar-71::after{
  content: "";
  display: block;
  padding-bottom: 71.21%;
}
.ar-71 > img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ar-62{
  position: relative;
  margin-bottom: 10px;
}
.ar-62::after{
  content: "";
  display: block;
  padding-bottom: 62.77%;
}
.ar-62 > img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ar-1x1{
  position: relative;
  margin-bottom: 1.5rem;
}
.ar-1x1::after{
  content: "";
  display: block;
  padding-bottom: 100%;
}
.ar-1x1 > img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ar-16x9{
  position: relative;
  margin-bottom: 1.5rem;
}
.ar-16x9::after{
  content: "";
  display: block;
  padding-bottom: 56.25%;
}
.ar-16x9 > img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ar-3x2{
  position: relative;
  margin-bottom: 1.5rem;
}
.ar-3x2::after{
  content: "";
  display: block;
  padding-bottom: 66.67%;
}
.ar-3x2 > img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ar-3x2-figure{
  position: relative;
  margin-bottom: 0.5rem;
}
.ar-3x2-figure::after{
  content: "";
  display: block;
  padding-bottom: 66.67%;
}
.ar-3x2-figure > img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ar-16x9-figure{
  position: relative;
  margin-bottom: 0.5rem;
}
.ar-16x9-figure::after{
  content: "";
  display: block;
  padding-bottom: 56.25%;
}
.ar-16x9-figure > img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel-caption-below-image{
  position: relative !important;
  top: 0;
  left: 50%;
  transform: translateX(-15%);

}

.iframe-container{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.responsive-iframe{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.pointer{
  cursor: pointer;
}
.img-overlay-wrap {
  position: relative;
  display: inline-block;
  transition: transform 150ms ease-in-out;
  outline-style: solid;
  outline-width: 2px;
  max-width: 540px;
  max-height: 540px;
  margin-left: auto;
  margin-right: auto;
  cursor:pointer;
}
.img-overlay-wrap img {
  display: block;
  max-width: 100%;
  height: auto;
  opacity:0;

}
.img-overlay-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  animation: dash 20s linear forwards;
  z-index:1;
}
.img-overlay-wrap:hover img {
  -webkit-animation: fadein 0.3s linear 1 normal forwards;
}

@-webkit-keyframes fadein{
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.img-overlay-wrap-small {
  position: relative;
  display: inline-block;
  transition: transform 150ms ease-in-out;
  outline-style: solid;
  outline-width: 2px;
  max-width: 500px;
  max-height: 500px;
  margin-left: auto;
  margin-right: auto;
}
.img-overlay-wrap-small img {
  display: block;
  max-width: 100%;
  height: auto;
  opacity:0;

}
.img-overlay-wrap-small svg {
  position: absolute;
  top: 0;
  left: 0;
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  animation: dash 20s linear forwards;
  z-index:1;
}
.img-overlay-wrap-small:hover img {
  -webkit-animation: fadein 0.3s linear 1 normal forwards;
}
.cls-1 {
  fill:none;stroke:#231f20;stroke-miterlimit:10;stroke-width:5px;
}
.st0{fill:none;stroke:#000000;stroke-width:5;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;stroke:#000000;stroke-width:5;stroke-miterlimit:10;}
.st2{fill:#010101;}
.st3{fill:none;stroke:#000000;stroke-width:3;stroke-miterlimit:10;}
.st4{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10.0002;}
.st5{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
.st6{stroke:#000000;stroke-width:5;stroke-miterlimit:10;}
.st7{stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
.st8{fill:#FFFFFF;stroke:#000000;stroke-width:3;stroke-miterlimit:10;}

#Layer_1{
  enable-background:new 0 0 800 800;
}
.navbarStyles{
  transition: top 0.4s;
}
.lightNavFontColor .navbar-brand > a{
  color: #FFFFFF!important;
}
.lightNavFontColor .navbar-nav > a{
  color: #FFFFFF!important;
}
.orbt-subtitle:hover p{
  display: none;
}
.orbt-subtitle:hover:before{
  content:"Product Design for a circular economy"
}
.subtitle{
  line-height: 1.3rem;
  margin-bottom: 12px;
}
.footer-font-size{
  font-size: 1.16rem;
  font-weight: 450;
  color: rgba(0,0,0,0.55);
}
.footer{
  margin-top:2rem;
  margin-bottom: 1.5rem;
}
.footer-font-size i{
  font-size: 1.5rem;
}
.footer-font-size i:hover{
  cursor: pointer;
  color: #e61428;
  transition: 0.4s ease;
}
.landing-page-intro{
  margin-top: 10.5rem!important;
  margin-bottom: 6rem;
}
.about-page{
  margin-top: 10.5rem!important;
  margin-bottom: 6rem;
}
.landing-page-intro p{
  font-size: 1.75rem;
  font-weight: 400;
}
.underline{
  text-decoration: underline!important;
}
.nav-link.active{
  text-decoration: underline;
}